.poppins{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.lato{
    font-family: 'Lato', sans-serif;
}
.Landing{
    min-height: 100vh;
    padding-top: 0%;
}
.banner-img{
    width: 100%
}
.swaps-img{
    width: 100%;
    margin-bottom: 5%;
}
.fiveRs-img{
    width: 70%;
    margin-left: 15%;
    margin-bottom: 5%;
}
.mission-div{
    font-size: 1.5rem;
    padding: 2% 15% 5% 15%;
    font-weight:400;
}
.weight600{
    font-weight: 600;
}
.landing-blog{
    padding: 2% 10% 2% 10%;
    text-align: left;
}
.l-b-title{
    font-size: 2.5rem;
}
.l-b-source{
    font-size: .6rem;
}
.landing-hr{
    width: 70%;
}
.form-email{
    font-size: 1.2rem;
}
.f-e-div{
    padding-bottom: 3%;
    padding-top: 1%;
    font-size: 1.4rem;
}
.form-email-input{
    border-radius: 1rem;
    text-align: center;
}
.form-email-btn{
    border-radius: 1rem;
    padding: .5% 2% .5% 2%;
    box-shadow: 1px 2px #B39778;
}
.landing-email{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
.clothing-img{
    width: 80%;
    margin-left: 12.5%;
    margin-bottom: 5%;
}
@media only screen and (min-width: 1068px) {
    .landing-blog{
        padding: 2% 20% 2% 20%;
        text-align: left;
    }
    .banner-img{
        width: 60%;
    }
    .swaps-img{
        width: 70%;
        margin-left: 15%;
    }
    .fiveRs-img{
        width: 50%;
        margin-left: 25%;
        margin-bottom: 5%;
    }
    .landing-email{
        width: 80%;
        margin-left: 10%;
    }
    .clothing-img{
        width: 50%;
        margin-left: 25%;
        margin-bottom: 5%;
    }
}







.light-green-font{
    color: #85C2A6
}
.light-green-background{
    background-color: #85C2A6
}
.cream-font{
    color: #FFF4E7
}
.cream-background{
    background-color: #FFF4E7
}
.brown-font{
    color: #B39778
}
.brown-backgroun{
    background-color: #B39778
}
.light-blue-font{
    color: #B2E6F2
}
.light-blue-background{
    background-color: #B2E6F2
}
.yellow-font{
    color: #FFE578;
}
.yellow-background{
    background-color: #FFE578;
}